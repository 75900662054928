import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ThemeProvider } from '@mui/material';
import React, { createContext, StrictMode } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { RootRouter } from './screens/root.router';
import { theme } from './shared/theme';
import { RootStore } from './stores/root.store';

const rootStore = new RootStore();
export const RootContext = createContext<RootStore>(rootStore);

ReactDOM.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <RootContext.Provider value={rootStore}>
        <RootRouter />
      </RootContext.Provider>
    </ThemeProvider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
