import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';
import { FunctionComponent, useContext, useEffect } from 'react';
import { RootContext } from '..';

const View = styled('div')`
  display: flex;
  flex-direction: column;
  flex: auto;
`;

const StyledDataGrid = styled(DataGrid)`
  flex: auto;
`;

export const InvoiceScreen: FunctionComponent = observer(() => {
  const { customerStore } = useContext(RootContext);

  useEffect(() => {
    customerStore.get();
  }, [customerStore]);
  return (
    <View>
      <Typography variant="h4">
        Voor welke klant wilt u een factuur maken?
      </Typography>
      <StyledDataGrid
        columns={
          [
            { field: 'firstName', headerName: 'Voornaam', width: 150 },
            { field: 'lastName', headerName: 'Achternaam', width: 150 },
          ] as GridColDef[]
        }
        rows={customerStore.customers}
      />
    </View>
  );
});
