import { Add } from '@mui/icons-material';
import { Fab } from '@mui/material';
import { styled } from '@mui/system';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { RootContext } from '../..';
import { toFlattenedObject } from '../../mappers/customer.mapper';
import { Customer } from '../../stores/customer.store';
import { MimeTypeNotSupportedError } from '../../types/google';
import { CustomerList } from './customer-list';
import { CustomerCodesModal } from './modals/customer-codes-modal';
import { NewCustomerModal } from './modals/new-customer-modal';

const CustomerLayout = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const StyledCustomerList = styled(CustomerList)`
  && {
    flex: 0 0 60vw; // TODO: find a way to make this work (https://github.com/styled-components/styled-components/issues/1253)
  }
`;

const BottomRightFab = styled(Fab)<{ index: number }>`
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  transform: translateY(-${({ index }) => index * 4}rem);
`;

export const CustomerScreen: FunctionComponent = observer(() => {
  const { customerStore, driveStore, notificationStore } =
    useContext(RootContext);
  const [isPickerDialogOpen, setPickerDialogOpen] = useState(false);
  const [customerForCodesDisplay, setCustomerForCodesDisplay] =
    useState<Customer>();

  useEffect(() => {
    customerStore.get();
  }, [customerStore]);

  return (
    <CustomerLayout>
      <StyledCustomerList
        customers={customerStore.customers}
        onCodeClick={(customer) => setCustomerForCodesDisplay(customer)}
        onDocumentClick={async (customer) => {
          const pickerResult = await driveStore.openPicker();
          if (pickerResult) {
            driveStore
              .searchAndReplace(
                pickerResult.id,
                pickerResult.mimeType,
                toFlattenedObject(toJS(customer))
              )
              .then((count) =>
                notificationStore.add({
                  id: 'docs_template_enriched',
                  message: (
                    <>
                      {!count ? 'Geen' : count} variabelen in het{' '}
                      <a
                        href={pickerResult.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        document
                      </a>{' '}
                      ingevuld
                    </>
                  ),
                  severity: !count ? 'warning' : 'success',
                })
              )
              .catch((error) =>
                notificationStore.add({
                  id: 'docs_template_error',
                  message:
                    error instanceof MimeTypeNotSupportedError
                      ? 'Dit type document wordt (nog) niet ondersteund'
                      : 'Er ging iets mis tijdens het invullen van het document',
                  severity: 'error',
                })
              );
          }
        }}
      />
      <BottomRightFab
        index={0}
        color="primary"
        aria-label="add"
        onClick={() => setPickerDialogOpen(true)}
      >
        <Add />
      </BottomRightFab>
      <NewCustomerModal
        open={isPickerDialogOpen}
        onClose={() => setPickerDialogOpen(false)}
      ></NewCustomerModal>
      <CustomerCodesModal
        customer={customerForCodesDisplay}
        onClose={() => setCustomerForCodesDisplay(undefined)}
      />
    </CustomerLayout>
  );
});
