import CodeIcon from '@mui/icons-material/Code';
import DocIcon from '@mui/icons-material/DocumentScanner';
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { FunctionComponent } from 'react';
import { Customer } from '../../stores/customer.store';

interface CustomerListProps {
  customers: Customer[];
  onCodeClick?: (customer: Customer) => void;
  onDocumentClick?: (customer: Customer) => void;
}

export const CustomerList: FunctionComponent<CustomerListProps> = ({
  customers,
  onCodeClick = () => {},
  onDocumentClick = () => {},
}) => {
  return (
    <List dense>
      {customers.length !== 0 ? (
        customers.map((customer) => (
          <ListItem
            key={customer.id}
            secondaryAction={
              <>
                <IconButton onClick={() => onCodeClick(customer)}>
                  <CodeIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  onClick={() => onDocumentClick(customer)}
                >
                  <DocIcon />
                </IconButton>
              </>
            }
          >
            <ListItemButton
              alignItems="flex-start"
              onClick={() =>
                window.open(
                  `https://contacts.google.com/person/${
                    customer.id?.split('/')[1]
                  }`,
                  '_blank'
                )
              }
            >
              <ListItemAvatar>
                <Avatar src={customer.photo} />
              </ListItemAvatar>
              <ListItemText
                primary={customer.firstName}
                secondary={
                  <>
                    <Typography
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {customer.lastName}
                    </Typography>
                    {customer.specialStatus}
                  </>
                }
              />
            </ListItemButton>
          </ListItem>
        ))
      ) : (
        <ListItem>
          <Typography>
            Geen klanten gevonden, voeg iemand toe om te beginnen...
          </Typography>
        </ListItem>
      )}
    </List>
  );
};
