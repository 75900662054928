import { AuthenticationStore } from './authentication.store';
import { ConfigurationStore } from './configuration.store';
import { CustomerStore } from './customer.store';
import { DriveStore } from './drive.store';
import { NotificationStore } from './notification.store';

export class RootStore {
  notificationStore = new NotificationStore();
  authenticationStore = new AuthenticationStore(this.notificationStore);
  driveStore = new DriveStore(this.authenticationStore);
  configurationStore = new ConfigurationStore(
    this.authenticationStore,
    this.driveStore
  );
  customerStore = new CustomerStore(this.configurationStore);
}
