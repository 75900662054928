import { makeAutoObservable } from 'mobx';
import { Configuration } from '../types/configuration';
import {
  DocsRequest,
  DocsResponse,
  DOCS_MIME_TYPE,
  GooglePicker,
  GooglePickerDocument,
  JSON_MIME_TYPE,
  MimeTypeNotSupportedError,
  SheetsRequest,
  SheetsResponse,
  SHEETS_MIME_TYPE,
} from '../types/google';
import { AuthenticationStore } from './authentication.store';

const CONFIG_FILE_NAME = 'config.json';

export class DriveStore {
  constructor(private authStore: AuthenticationStore) {
    makeAutoObservable(this);
    gapi.load('picker', () => {});
  }

  async getConfiguration(): Promise<Configuration | undefined> {
    const {
      result: { files },
    } = await gapi.client.drive.files.list({
      spaces: 'appDataFolder',
      fields: 'files(id, name)',
    });
    const configFile = files?.find((file) => file.name === CONFIG_FILE_NAME);
    if (configFile?.id) {
      const { result } = await gapi.client.drive.files.get({
        fileId: configFile.id,
        alt: 'media',
      });
      return result as Configuration;
    }
  }

  async createConfigurationFile(config: Configuration) {
    const {
      result: { id },
    } = await gapi.client.drive.files.create({
      // @ts-ignore
      name: CONFIG_FILE_NAME,
      parents: ['appDataFolder'],
      mimeType: JSON_MIME_TYPE,
      fields: 'id, name, kind, size',
    });
    await fetch(`https://www.googleapis.com/upload/drive/v3/files/${id}`, {
      method: 'PATCH',
      headers: new Headers({
        Authorization: `Bearer ${this.authStore.authToken}`,
        'Content-Type': JSON_MIME_TYPE,
      }),
      body: new Blob([JSON.stringify(config)], { type: JSON_MIME_TYPE }),
    });
  }

  private picker?: GooglePicker;
  private pickerResolution: (val?: GooglePickerDocument) => void = () => {};

  openPicker() {
    if (!this.picker) {
      this.createPicker();
    }
    this.picker?.setVisible(true);

    return new Promise<GooglePickerDocument | void>((resolve) => {
      this.pickerResolution = resolve;
    });
  }

  private createPicker() {
    this.picker = new google.picker.PickerBuilder()
      .addViewGroup(
        new google.picker.ViewGroup(google.picker.ViewId.DOCS)
          .addView(google.picker.ViewId.DOCUMENTS)
          .addView(google.picker.ViewId.SPREADSHEETS)
      )
      .enableFeature(google.picker.Feature.NAV_HIDDEN)
      .setOAuthToken(this.authStore.authToken)
      .setCallback(({ action, docs }) => {
        if (action === google.picker.Action.PICKED) {
          this.pickerResolution(docs[0]);
        } else if (action === google.picker.Action.CANCEL) {
          this.pickerResolution();
        }
      })
      .build();
  }

  async searchAndReplace(
    documentId: string,
    mimeType: string,
    object: { [key: string]: string }
  ) {
    const changedOccurencesFrom = (
      replies?: DocsResponse[] & SheetsResponse[]
    ) =>
      replies?.reduce((acc, next) => {
        const occurrencesChanged =
          next.replaceAllText?.occurrencesChanged ||
          (next as SheetsResponse).findReplace?.occurrencesChanged ||
          0;
        return acc + occurrencesChanged;
      }, 0) || 0;

    if (mimeType === DOCS_MIME_TYPE) {
      const requests: DocsRequest[] = Object.entries(object).map(
        ([key, val]) => ({
          replaceAllText: {
            containsText: {
              text: `{{${key}}}`,
              matchCase: true,
            },
            replaceText: val as string,
          },
        })
      );
      const {
        result: { replies },
      } = await gapi.client.docs.documents.batchUpdate({
        documentId,
        resource: {
          requests,
        },
      });
      return changedOccurencesFrom(replies);
    } else if (mimeType === SHEETS_MIME_TYPE) {
      const requests: SheetsRequest[] = Object.entries(object).map(
        ([key, val]) => ({
          findReplace: {
            find: `{{${key}}}`,
            replacement: val,
            matchCase: true,
            matchEntireCell: false,
            searchByRegex: false,
            includeFormulas: true,
            allSheets: true,
          },
        })
      );

      const {
        result: { replies },
      } = await gapi.client.sheets.spreadsheets.batchUpdate({
        spreadsheetId: documentId,
        resource: {
          requests,
        },
      });
      return changedOccurencesFrom(replies);
    } else {
      throw new MimeTypeNotSupportedError();
    }
  }
}
