import { Modal, ModalProps, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import { FunctionComponent, useContext } from 'react';
import { RootContext } from '../../..';
import { toFlattenedObject } from '../../../mappers/customer.mapper';
import { Customer } from '../../../stores/customer.store';

const ModalBox = styled(Box)`
  background-color: ${({
    theme: {
      palette: {
        primary: { dark },
      },
    },
  }) => dark};
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  color: white;
  border-radius: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
`;

const CodeLine = styled(Typography)`
  :hover {
    background: ${({
      theme: {
        palette: {
          primary: { light },
        },
      },
    }) => light};
  }
  cursor: pointer;
`;

const objectToCodes = (object: { [key: string]: string }) =>
  Object.entries(object)
    .filter(([, value]) => !!value)
    .map(([key, value]) => [
      `{{${key}}}`,
      value.length > 30 ? `${value.substring(0, 30)}...` : value,
    ]);

export const CustomerCodesModal: FunctionComponent<
  Omit<ModalProps, 'children' | 'open' | 'sx'> & { customer?: Customer }
> = ({ customer, ...otherModalProps }) => {
  const { notificationStore } = useContext(RootContext);
  return (
    <Modal {...otherModalProps} open={!!customer}>
      {customer ? (
        <ModalBox>
          {objectToCodes(toFlattenedObject(customer)).map(([code, value]) => (
            <Box key={code}>
              <CodeLine
                variant="body1"
                display="inline"
                gutterBottom
                onClick={() => {
                  navigator.clipboard.writeText(code);
                  notificationStore.add({
                    id: `copied_${code}`,
                    message: `Code ${code} gekopiëerd`,
                    severity: 'info',
                  });
                }}
              >
                {code}
              </CodeLine>
              <Typography variant="body1" display="inline" gutterBottom>
                : {value}
              </Typography>
            </Box>
          ))}
        </ModalBox>
      ) : (
        <>{'</>'}</>
      )}
    </Modal>
  );
};
