import { action, makeAutoObservable } from 'mobx';
import { customerToPerson, personToCustomer } from '../mappers/customer.mapper';
import { ContactGroup, Person } from '../types/google';
import { ConfigurationStore } from './configuration.store';

export interface Customer {
  id?: string;
  firstName: string;
  middleName: string;
  lastName: string;
  address: {
    streetAndNumber: string;
    postalCode: string;
    city: string;
    country: string;
  };
  photo?: string;
  nationality?: string;
  placeOfBirth?: string;
  dateOfBirth?: number;
  nationalNumber?: number;
  gender?: string;
  specialStatus?: string;
  userDefined?: {
    [key: string]: string;
  };
}

export class CustomerStore {
  customers: Customer[] = [];

  constructor(private configStore: ConfigurationStore) {
    makeAutoObservable(this);
  }

  @action async get() {
    const companyContactGroup = await this.getOrCreateContactGroup();
    const companyContactGroupMembers = companyContactGroup.memberResourceNames;
    if (!companyContactGroupMembers) {
      return;
    }

    const {
      result: { responses },
    } = await gapi.client.people.people.getBatchGet({
      resourceNames: companyContactGroupMembers,
      personFields:
        'addresses,ageRanges,biographies,birthdays,calendarUrls,clientData,coverPhotos,emailAddresses,events,externalIds,genders,imClients,interests,locales,locations,memberships,metadata,miscKeywords,names,nicknames,occupations,organizations,phoneNumbers,photos,relations,sipAddresses,skills,urls,userDefined',
    });

    this.customers =
      responses
        ?.map(({ person }) => person)
        .filter((person) => !!person)
        .map((person) => personToCustomer(person!)) || [];
  }

  @action async createCustomer(customer: Customer): Promise<Person> {
    const companyContactGroup = await this.getOrCreateContactGroup();

    const createdContact = (
      await gapi.client.people.people.createContact(
        {},
        customerToPerson(customer)
      )
    ).result;

    await gapi.client.people.contactGroups.members.modify(
      { resourceName: companyContactGroup?.resourceName! },
      { resourceNamesToAdd: [createdContact.resourceName!] }
    );

    await gapi.client.people.people.updateContactPhoto(
      { resourceName: createdContact.resourceName! },
      { photoBytes: customer.photo?.replaceAll('\n', '') }
    );

    this.customers.push(customer);

    return createdContact;
  }

  private async getOrCreateContactGroup(): Promise<ContactGroup> {
    const contactGroupName = this.configStore.get('contactGroupName');
    const {
      result: { contactGroups },
    } = await gapi.client.people.contactGroups.list({
      pageSize: 1000,
    });
    let companyContactGroup = contactGroups?.find(
      ({ name }) => name === contactGroupName
    );

    if (!companyContactGroup) {
      const { result } = await gapi.client.people.contactGroups.create(
        {},
        {
          contactGroup: {
            name: contactGroupName,
          },
        }
      );
      companyContactGroup = result;
    }

    const { result } = await gapi.client.people.contactGroups.get({
      resourceName: companyContactGroup?.resourceName!,
      maxMembers: 10000,
    });
    return result;
  }
}
