import { Alert, Avatar, Snackbar } from '@mui/material';
import { styled } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { FunctionComponent, useContext } from 'react';
import { RootContext } from '..';

const StyledLayout = styled('div')`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`;

const StyledAvatar = styled(Avatar)`
  position: absolute !important;
  top: 0.8rem;
  right: 1rem;
`;

const StyledAlert = styled(Alert)`
  a {
    color: white;
  }
`;

export const Layout: FunctionComponent = observer(({ children }) => {
  // const location = useLocation();
  // const history = useHistory();
  const {
    authenticationStore,
    authenticationStore: { userName, photoUrl },
    notificationStore,
    notificationStore: { notification },
  } = useContext(RootContext);

  return (
    <StyledLayout>
      {children}
      <StyledAvatar
        alt={userName}
        src={photoUrl}
        onClick={() => authenticationStore.signOut()}
      />
      <Snackbar
        open={!!notification}
        autoHideDuration={10000}
        onClose={() => notificationStore.remove(notification!.id)}
        message={notification?.message}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <StyledAlert severity={notification?.severity}>
          {notification?.message}
        </StyledAlert>
      </Snackbar>
      {/* <BottomNavigation
        value={location.pathname}
        onChange={(_, route) => history.push(route)}
        showLabels
      >
        <BottomNavigationAction
          label="Klanten"
          value={ROUTES.CUSTOMERS}
          icon={<People />}
        />
        <BottomNavigationAction
          label="Facturen"
          value={ROUTES.INVOICES}
          icon={<Description />}
        />
      </BottomNavigation> */}
    </StyledLayout>
  );
});
