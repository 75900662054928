import { Modal, ModalProps } from '@mui/material';
import { Box, styled } from '@mui/system';
import { FunctionComponent, useContext } from 'react';
import { RootContext } from '../../..';
import { eidToCustomer, xmlToEid } from '../../../mappers/eid.mapper';
import { Dropzone } from '../../../shared/dropzone';

const ModalBox = styled(Box)`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20rem;
  height: 15rem;
  padding: 4rem;
`;

const StyledDropzone = styled(Dropzone)`
  flex: 1 1 auto;
  height: 100%;
`;

export const NewCustomerModal: FunctionComponent<
  Omit<ModalProps, 'children' | 'sx'>
> = (modalProps) => {
  const { customerStore, notificationStore } = useContext(RootContext);
  return (
    <Modal {...modalProps}>
      <ModalBox>
        <StyledDropzone
          onReadFile={async (fileContent) => {
            const customer = eidToCustomer(xmlToEid(fileContent));
            try {
              const createdContact = await customerStore.createCustomer(
                customer
              );
              const contactUrl = `https://contacts.google.com/person/${createdContact.resourceName?.replace(
                `people/`,
                ''
              )}`;
              notificationStore.add({
                id: 'contact_create_success',
                message: (
                  <>
                    Klant{' '}
                    <a href={contactUrl} target="_blank" rel="noreferrer">
                      {customer.firstName}
                    </a>{' '}
                    succesvol aangemaakt
                  </>
                ),
              });
            } catch (e) {
              notificationStore.add({
                id: 'contact_create_error',
                message: `Klant ${customer.firstName} aanmaken mislukt`,
                severity: 'error',
              });
            }
          }}
        />
      </ModalBox>
    </Modal>
  );
};
