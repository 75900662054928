import xmltojson from 'xmltojson';
import { Customer } from '../stores/customer.store';

export interface Eid {
  identity: {
    _attr: {
      nationalnumber: number;
      dateofbirth: number;
      gender: string;
      specialstatus: string;
    };
    name: string;
    firstname: string;
    middlenames?: string;
    nationality: string;
    placeofbirth: string;
    photo: string;
  };
  address: {
    streetandnumber: string;
    zip: number;
    municipality: string;
  };
}

const removeIntermediateFields = (obj: any) =>
  Object.keys(obj).forEach((key) => {
    !!obj[key] && obj[key]._text !== undefined
      ? (obj[key] = obj[key]._text)
      : !!obj[key] && obj[key]._value !== undefined
      ? (obj[key] = obj[key]._value)
      : !!obj[key] &&
        typeof obj[key] === 'object' &&
        removeIntermediateFields(obj[key]);
  });

export const xmlToEid = (xml: string): Eid => {
  const xmlEidMatch = xml.match(/<eid>.*<\/eid>/ms);
  const eidRawJson: any =
    !!xmlEidMatch &&
    xmltojson.parseString(xmlEidMatch[0], { childrenAsArray: false });
  const { eid } = eidRawJson;
  removeIntermediateFields(eid);
  return eid;
};

export const eidToCustomer = ({
  identity: {
    firstname: firstName = '',
    middlenames: middleName = '',
    name: lastName = '',
    photo,
    placeofbirth: placeOfBirth,
    nationality,
    _attr: {
      dateofbirth: dateOfBirth,
      gender,
      nationalnumber: nationalNumber,
      specialstatus: specialStatus,
    },
  },
  address: { streetandnumber: streetAndNumber, zip, municipality: city },
}: Eid): Customer => ({
  firstName,
  middleName,
  lastName,
  photo,
  address: {
    streetAndNumber,
    postalCode: String(zip),
    city,
    country: 'België',
  },
  nationality,
  placeOfBirth,
  dateOfBirth,
  nationalNumber,
  gender,
  specialStatus,
});
