import { action, makeAutoObservable } from 'mobx';
import { Auth, User } from '../types/google';
import { NotificationStore } from './notification.store';

const NEEDED_SCOPES = [
  'email',
  'profile',
  'https://www.googleapis.com/auth/contacts',
  'https://www.googleapis.com/auth/drive',
  'https://www.googleapis.com/auth/drive.appdata',
  'https://www.googleapis.com/auth/drive.file',
];

export class AuthenticationStore {
  auth?: Auth;
  isSignedIn = false;

  constructor(private notificationStore: NotificationStore) {
    makeAutoObservable(this);
    this.initAuth();
  }

  private async initAuth() {
    await Promise.all([
      new Promise((res) => gapi.load('auth2', res)),
      new Promise((res) => gapi.load('client', res)),
    ]);
    gapi.auth2.init({
      client_id:
        '725404849805-c2leckgsb4na358be39d3ij9gsn7t3rr.apps.googleusercontent.com',
      scope: NEEDED_SCOPES.join(' '),
    });
    await gapi.client.init({
      discoveryDocs: [
        'https://people.googleapis.com/$discovery/rest',
        'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
        'https://docs.googleapis.com/$discovery/rest',
        'https://sheets.googleapis.com/$discovery/rest?version=v4',
      ],
    });

    this.auth = gapi.auth2.getAuthInstance();
    this.addSignedInListener(this.auth);
  }

  private addSignedInListener(auth: Auth) {
    const signedInHandler = (isSignedIn: boolean) => {
      if (!isSignedIn) {
        return;
      }
      this.notificationStore.add({
        id: 'signed_in',
        message: `Welkom terug, ${auth.currentUser
          .get()
          .getBasicProfile()
          .getGivenName()}`,
        severity: 'info',
      });
      this.verifyScopes(auth.currentUser);
    };

    // Check if user is already signed in
    this.isSignedIn = auth.isSignedIn.get();
    signedInHandler(this.isSignedIn);

    auth.isSignedIn.listen((isSignedIn) => {
      this.isSignedIn = isSignedIn;
      signedInHandler(isSignedIn);
    });
  }

  private verifyScopes(user: User) {
    console.log('User updated, verifying scopes...');
    const grantedScopes = user.get().getGrantedScopes().split(' ');
    const areAllNeededScopesGranted = NEEDED_SCOPES.every((neededScope) =>
      grantedScopes.includes(neededScope)
    );
    if (!areAllNeededScopesGranted) {
      const timeout = 5000;
      this.notificationStore.add({
        id: 'scope_mismatch',
        message: `De app heeft nieuwe rechten nodig en zal u even uitloggen over ${
          timeout / 1000
        } seconden...`,
        severity: 'warning',
        prio: true,
      });
      setTimeout(() => this.signOut(), timeout);
    }
  }

  get userName() {
    return this.auth?.currentUser.get().getBasicProfile().getName() || '';
  }

  get photoUrl() {
    return this.auth?.currentUser.get().getBasicProfile().getImageUrl() || '';
  }

  get authToken() {
    return (
      this.auth?.currentUser.get().getAuthResponse(true)?.access_token || ''
    );
  }

  @action async signIn() {
    await this.auth?.signIn();
  }

  @action async signOut() {
    this.auth?.signOut();
  }
}
