import { action, makeAutoObservable } from 'mobx';
import { ReactFragment } from 'react';

export interface Notification {
  id: string;
  message: string | ReactFragment;
  severity?: 'success' | 'warning' | 'info' | 'error';
  prio?: boolean;
}

export class NotificationStore {
  private notifications: Notification[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get notification(): Notification | undefined {
    const [notification] = this.notifications;
    return notification;
  }

  @action
  add(notification: Notification) {
    this.notifications = notification.prio
      ? [notification]
      : [...this.notifications, { severity: 'success', ...notification }];
  }

  @action
  remove(notificationId: string) {
    this.notifications = this.notifications.filter(
      ({ id }) => notificationId !== id
    );
  }
}
