import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { FunctionComponent, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const Zone = styled('div')`
  background-color: darkgreen;
  color: white;
  flex: 1 1 auto;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export interface DropzoneProps {
  onReadFile: (fileContent: string) => void;
}

export const Dropzone: FunctionComponent<DropzoneProps> = ({ onReadFile }) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0) {
        return;
      }
      const reader = new FileReader();
      reader.onload = () => onReadFile(reader.result as string);
      reader.readAsText(acceptedFiles[0]);
    },
    [onReadFile]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Zone {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Hierzo!</p>
      ) : (
        <Typography variant="caption">
          Drag 'n' drop de foto of het bestand hier, of klik om een bestand te
          selecteren.
        </Typography>
      )}
    </Zone>
  );
};
