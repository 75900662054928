import GoogleIcon from '@mui/icons-material/Google';
import { Button, Grid, styled } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FunctionComponent, useContext } from 'react';
import { RootContext } from '..';

const FlexedGrid = styled(Grid)`
  flex: 1 1 auto;
`;

const Centered = styled(Grid)`
  justify-content: center;
  display: flex;
`;

export const LoginScreen: FunctionComponent = observer(() => {
  const { authenticationStore } = useContext(RootContext);
  return (
    <FlexedGrid container alignContent="center">
      <Grid item xs={4}></Grid>
      <Centered item xs={4}>
        <Button
          startIcon={<GoogleIcon />}
          onClick={() => authenticationStore.signIn()}
        >
          Log in met Google
        </Button>
      </Centered>
      <Grid item xs={4}></Grid>
    </FlexedGrid>
  );
});
