import { Customer } from '../stores/customer.store';
import { Person } from '../types/google';

const CUSTOMER_VARIABLE_NAME = 'customer';

// TODO: improve mapping (don't add property when not available)
export const personToCustomer = ({
  resourceName,
  names,
  addresses,
  photos,
  userDefined,
}: Person): Customer => ({
  id: resourceName,
  firstName: names?.[0]?.givenName || '',
  middleName: names?.[0]?.middleName || '',
  lastName: names?.[0]?.familyName || '',
  address: {
    streetAndNumber: addresses?.[0]?.streetAddress || '',
    postalCode: addresses?.[0]?.postalCode || '',
    city: addresses?.[0]?.city || '',
    country: addresses?.[0]?.country || '',
  },
  photo: photos?.[0]?.url,
  userDefined: userDefined
    ? Object.fromEntries(userDefined.map(({ key, value }) => [key, value]))
    : undefined,
});

export const customerToPerson = ({
  firstName,
  middleName,
  lastName,
  address: { streetAndNumber, postalCode, city, country },
  dateOfBirth = 0,
  gender,
  nationality,
  placeOfBirth,
  nationalNumber,
  specialStatus,
}: Customer): Person => ({
  names: [{ givenName: firstName, middleName, familyName: lastName }],
  addresses: [{ streetAddress: streetAndNumber, postalCode, city, country }],
  birthdays: [
    {
      date: {
        year: Math.round(dateOfBirth / 10000),
        month: Math.round(dateOfBirth / 100) % 100,
        day: dateOfBirth % 100,
      },
    },
  ],
  genders: [{ value: gender }],
  userDefined: [
    { key: 'Nationaliteit', value: nationality },
    { key: 'Rijkregisternummer', value: `${nationalNumber}` },
    { key: 'Geboorteplaats', value: placeOfBirth },
    { key: 'Status', value: specialStatus },
  ],
});

export const toFlattenedObject = (
  customer: Customer,
  prefix = CUSTOMER_VARIABLE_NAME
): { [key: string]: string } => {
  const entriesOfType = (type: 'string' | 'object') =>
    Object.entries(customer).filter(([, val]) => typeof val === type);

  return {
    ...entriesOfType('string').reduce(
      (acc, [key, val]) => ({
        ...acc,
        [`${prefix}.${key}`]: val,
      }),
      {}
    ),
    ...entriesOfType('object')
      .map(([key, value]) => toFlattenedObject(value, `${prefix}.${key}`))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
  };
};
