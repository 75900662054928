import { observer } from 'mobx-react-lite';
import { FunctionComponent, useContext } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { RootContext } from '..';
import { LoginScreen } from '../screens/login.screen';
import { Layout } from '../shared/layout';
import { CustomerScreen } from './customers/customer.screen';
import { InvoiceScreen } from './invoice.screen';
import { LoadingScreen } from './loading.screen';
import { SetupWizard } from './setup.wizard';

export enum ROUTES {
  ROOT = '/',
  CUSTOMERS = '/customers',
  INVOICES = '/invoices',
}

export const RootRouter: FunctionComponent = observer(() => {
  const {
    authenticationStore: { isSignedIn },
    configurationStore: { isInitialized, isLoaded },
  } = useContext(RootContext);
  if (!isSignedIn) {
    return <LoginScreen />;
  }
  if (!isInitialized && !isLoaded) {
    return <LoadingScreen />;
  }
  if (!isInitialized && isLoaded) {
    return <SetupWizard />;
  }
  return (
    <Router>
      <Layout>
        <Switch>
          <Route path={ROUTES.CUSTOMERS}>
            <CustomerScreen />
          </Route>
          <Route path={ROUTES.INVOICES}>
            <InvoiceScreen />
          </Route>
          <Route path={ROUTES.ROOT}>
            <Redirect to={{ pathname: ROUTES.CUSTOMERS }} />
          </Route>
        </Switch>
      </Layout>
    </Router>
  );
});
