// Google Sign in API
export type Auth = gapi.auth2.GoogleAuth;
export type User = gapi.auth2.CurrentUser;

// People API
export type Person = gapi.client.people.Person;
export type ContactGroup = gapi.client.people.ContactGroup;

// Drive API
export type GooglePicker = google.picker.Picker;
export type GooglePickerResponse = google.picker.ResponseObject;
export type GooglePickerDocument = google.picker.DocumentObject;
export type File = gapi.client.drive.File;

// Docs API
export type DocsRequest = gapi.client.docs.Request;
export type DocsResponse = gapi.client.docs.Response;

// Sheets API
export type SheetsRequest = gapi.client.sheets.Request;
export type SheetsResponse = gapi.client.sheets.Response;

// Drive constants
export const JSON_MIME_TYPE = 'application/json';
export const DOCS_MIME_TYPE = 'application/vnd.google-apps.document';
export const SHEETS_MIME_TYPE = 'application/vnd.google-apps.spreadsheet';
export class MimeTypeNotSupportedError extends Error {}
